import React from "react";
import { useStaticQuery, graphql } from "gatsby"

import Heading from "~/blocks/Heading/Heading.js";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Slider from "react-slick";

import '~/overrides/slick/slick.scss'
import '~/overrides/slick/slick-theme.scss'
import './MainBanner.scss'

/*
	MAIN BANNER: website's home page main banner
*/
const MainBanner = () => {
	const data = useStaticQuery(graphql`
		query HomeBannersQuery {
			allHomeBannersJson {
				nodes {
					id
					title
					url
					imageUrl {
						childImageSharp {
							fluid(maxWidth: 1280, quality: 80) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);

	const banners = data.allHomeBannersJson.nodes;

	var settings = {
		infinite: true,
		speed: 1500,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: true,
		fade: true,
		className: "main-banner__slider"
	};

	return (
		<section className="main-banner" aria-label="Destaques">
			<Slider {...settings}>
				{banners.map( banner => {
					const SlideTag = (banner.url ? Link : 'div');

					return (
						<SlideTag
							key={banner.id}
							to={banner.url}
							className="main-banner__slide"
						>
							<Img
								fluid={ banner.imageUrl.childImageSharp.fluid }
								className="main-banner__image"
								alt=""
							/>

							<div className="main-banner__content">
								<Heading
									level={2}
									className="h1 main-banner__title"
								>
									{ banner.title }
								</Heading>
								<p className="main-banner__paragraph">
									{ banner.paragraph }
								</p>
							</div>
						</SlideTag>
					)
				})}
			</Slider>
		</section>
	)
}

export default MainBanner;
