import React, { useState, useEffect } from 'react';
import useWindowDimensions from '~/hooks/useWindowDimensions.js';
import { useStaticQuery, graphql } from "gatsby"

import Slider from "react-slick";
import Heading from "~/blocks/Heading/Heading.js"
import Img from "gatsby-image";

import "./Roda.scss"

/*
	R.O.D.A.: the company's motto
*/
const Roda = (props) => {
	const rodaImages = useStaticQuery(graphql`
		query rodaQuery {
			rodaR: file(
				relativePath: {
					eq: "roda/r.png"
				}
			) {
				childImageSharp {
					fixed(width: 2450, quality: 80) {
						...GatsbyImageSharpFixed
					}
				}
			}

			rodaO: file(
				relativePath: {
					eq: "roda/o.png"
				}
			) {
				childImageSharp {
					fixed(width: 2450, quality: 80) {
						...GatsbyImageSharpFixed
					}
				}
			}

			rodaD: file(
				relativePath: {
					eq: "roda/d.png"
				}
			) {
				childImageSharp {
					fixed(width: 2450, quality: 80) {
						...GatsbyImageSharpFixed
					}
				}
			}

			rodaA: file(
				relativePath: {
					eq: "roda/a.png"
				}
			) {
				childImageSharp {
					fixed(width: 2450, quality: 80) {
						...GatsbyImageSharpFixed
					}
				}
			}

			site {
				siteMetadata {
					breakpoints {
						md
					}
				}
			}
		}
	`);

	const letterArray = [];

	for (const letter in rodaImages) {
		if (letter.indexOf('roda') !== -1) {
			letterArray.push(rodaImages[letter]);
		}
	}

	const level = props.level ? props.level : 1;
	const [slideIndex, setSlideIndex] = useState(1); 
	const { width } = useWindowDimensions();
	const {	breakpoints } = rodaImages.site.siteMetadata;

	let initialIsRodaVisible = (width > breakpoints.md) ? true : false;
	const [isRodaVisible, setIsRodaVisible] = useState(initialIsRodaVisible);
	
	// When the window changes, the initial visible can change.
	// If it does, sets the visible state to the new initial state.
	useEffect(() => {
		if (initialIsRodaVisible !== isRodaVisible) {
			setIsRodaVisible(initialIsRodaVisible);
		}
	}, [initialIsRodaVisible]);

	let rodaSettings = {
		infinite: false,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		autoplay: false,
		fade: true,
		className: "roda__text-slider",
		beforeChange: function ( oldIndex, newIndex ) {
			setSlideIndex(newIndex+1);
		}
	};
	
	return (
		<div className="roda">
			<div className="roda__centered centered">
				<Heading
					level={level}
					className="roda__title"
				>
					Desafiados pelo Futuro
				</Heading>
				<p className="roda__intro">Nosso jeito de ser nos orienta a seguir sempre:</p>

				<div className="grid grid--2-columns-md-up grid--gap--1 mt--1 mt--2-md-up">
					<div className="roda__column grid__item">
						<Slider {...rodaSettings}>
							<div className="roda__text-slide">
								<Heading
									level={level + 1}
									className="h2 roda__subtitle"
								>
									Realizadores
								</Heading>
								<div className="roda__content content">
									<p>
										<strong>Fazer acontecer</strong> é com a gente mesmo. Adoramos um desafio, e seguimos sempre em frente com <strong>autonomia</strong> para tomar decisões <strong>eficientes</strong> na direção dos melhores resultados.
									</p>
								</div>
							</div>

							<div className="roda__text-slide">
								<Heading
									level={level + 1}
									className="h2 roda__subtitle"
								>
									Obcecados pelo Cliente
								</Heading>
								<div className="roda__content content">
									<p>
										A gente não desiste até ver o cliente feliz. Nos colocamos no lugar de cada um pra antecipar o que precisam. E na hora de resolver problemas, vamos direto ao ponto com muita transparência.
									</p>
								</div>
							</div>

							<div className="roda__text-slide">
								<Heading
									level={level + 1}
									className="h2 roda__subtitle"
								>
									Desafiados
								</Heading>
								<div className="roda__content content">
									<p>
										Olhamos lá na frente para transformar o impossível em realidade. Pessoas são o coração, inquietude o combustível, e a tecnologia nosso motor pra avançar na vanguarda do setor.
									</p>
								</div>
							</div>

							<div className="roda__text-slide">
								<Heading
									level={level + 1}
									className="h2 roda__subtitle"
								>
									Atentos ao Outro
								</Heading>
								<div className="roda__content content">
									<p>
										Cada um é parte do todo. Celebramos juntos cada conquista e nos apoiamos a cada incerteza. Com respeito, colaboração e troca, vamos com tudo!
									</p>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</div>
			
			{ initialIsRodaVisible ? 
				<div className="roda__graph-holder">
					<div className={`roda__graph roda__graph--stage--${slideIndex}`}>
						{ letterArray.map( letter => {
							return (
								<Img
									key={ letter.childImageSharp.fixed.src }
									fixed={ letter.childImageSharp.fixed }
									className="roda__letter"
									alt=""
								/>
							)
						})}
					</div>
				</div>
				: null
			}
		</div>
	)
};

export default Roda;