import React from "react"
import { graphql } from "gatsby"

import Page from "~/blocks/Page/Page.js"
import SEO from "~/blocks/Seo/Seo.js"
import Heading from "~/blocks/Heading/Heading.js"
import Icon from "~/blocks/Icon/Icon.js"
import Img from "gatsby-image";
import MainBanner from "~/blocks/MainBanner/MainBanner.js"
import Roda from "~/blocks/Roda/Roda.js"

import "~/helper-blocks/Content/Content.scss"
import "./Index.scss"

/*
	HOME PAGE
*/
const IndexPage = ({data}) => {
	const personalLink = data.site.siteMetadata.routes.filter( route => route.anchorText === 'Para Pessoas');
	const businessLink = data.site.siteMetadata.routes.filter( route => route.anchorText === 'Para Negócios');

	return (
		<Page
			title=""
			stuckTop={true}
		>
			<SEO
				title=""
				description="Onde alguns veem pneus, a gente enxerga caminhos. Conheça a CantuStore."
				pathname="/"
			/>

			<MainBanner />

			<div className="home-intro">
				<Icon
					symbol="arrow-down"
					mix="home-intro__arrow"
				/>

				<div className="home-intro__centered centered">
					<div className="home-intro__text-container">
						<Heading
							level={2}
							className="home-intro__title"
						>
							Existimos para abrir caminhos para pessoas e negócios.
						</Heading>

						<div className="home-intro__content content">
							<p>Nossas soluções dão mobilidade para cada um poder explorar seu potencial por inteiro.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="home-solutions grid grid--2-columns-md-up">
				<div className="home-solutions__text-container grid__item grid__item--full-width">
					<div className="home-solutions__centered centered">
						<Heading
							level={2}
							className="home-solutions__title"
						>
							Somos a <span className="home-solutions__title-detail">CantuStore</span>
						</Heading>

						<div className="home-solutions__content content">
							<p>A empresa brasileira de tecnologia e logística que viabiliza soluções completas em pneus, guiando quem compra e apoiando quem vende.</p>
						</div>
					</div>
				</div>

				<a
					href={ personalLink[0].path }
					target={ personalLink[0].openInNewTab ? '_blank' : null}
					rel={ personalLink[0].openInNewTab ? 'noreferrer noopener' : null}
					className="home-solution home-solution--type--personal"
				>
					<Img
						fluid={ data.homeSolutionImage1.childImageSharp.fluid }
						className="home-solution__image"
						alt=""
					/>

					<span className="home-solution__title-holder">
						<span className="home-solution__title">
							Para pessoas
						</span>
						<span className="home-solution__icon-holder">
							<Icon
								symbol="arrow-right"
								mix="home-solution__icon"
							/>
						</span>
					</span>
				</a>

				<a
					href={ businessLink[0].path }
					target={ businessLink[0].openInNewTab ? '_blank' : null}
					rel={ businessLink[0].openInNewTab ? 'noreferrer noopener' : null}
					className="home-solution home-solution--type--business"
				>
					<Img
						fluid={ data.homeSolutionImage2.childImageSharp.fluid }
						className="home-solution__image"
						alt=""
					/>

					<span className="home-solution__title-holder">
						<span className="home-solution__title">
							Para negócios
						</span>
						<span className="home-solution__icon-holder">
							<Icon
								symbol="arrow-right"
								mix="home-solution__icon"
							/>
						</span>
					</span>
				</a>
			</div>

			{/* <div className="home-numbers">
				<div className="home-numbers__centered centered">
					<Heading
						level={2}
						className="home-numbers__title"
					>
						Cantu em Números
					</Heading>

					<div className="home-number">
						<span className="home-number__value">
							1500
						</span>
						<span className="home-number__label">
							Colaboradores
						</span>
					</div>

					<div className="home-number">
						<span className="home-number__value">
							35<abbr title="milhões">MM</abbr>
						</span>
						<span className="home-number__label">
							De pneus vendidos
						</span>
					</div>

					<div className="home-number">
						<span className="home-number__value">
							78
						</span>
						<span className="home-number__label">
							Marcas parceiras
						</span>
					</div>

					<div className="home-number">
						<span className="home-number__value">
							40
						</span>
						<span className="home-number__label">
							Unidades pelo Brasil
						</span>
					</div>
				</div>
			</div> */}

			<Roda level={2} />
			
			<div className="centered mt--1 mt--2-md-up ml--1-lg-down mr--1-lg-down">
				<a
					target="_blank"
					rel="noreferrer noopener"
					href="https://cantustore.gupy.io/"
					className="jobs-cta"
				>
					<span className="jobs-cta__icon-holder">
						<Icon
							symbol="hand-paper"
							mix="jobs-cta__icon"
						/>
					</span>

					<span className="jobs-cta__content-holder">
						<Heading className="jobs-cta__title">
							Não é sobre pneus, é sobre abrir caminhos para o seu crescimento.
						</Heading>

						<span className="jobs-cta__cta-phrase">
							Se identificou? Conheça nossas vagas.
						</span>

						<Icon
							symbol="arrow-right"
							aria-hidden="true"
							mix="jobs-cta__arrow"
						/>
					</span>
				</a>
			</div>
		</Page>
	)
}

export default IndexPage;

export const query = graphql`
	{
		homeSolutionImage1: file(
			relativePath: {
				eq: "para-pessoas.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 640, quality: 80) {
					...GatsbyImageSharpFluid
				}
			}
		}

		homeSolutionImage2: file(
			relativePath: {
				eq: "para-negocios.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 640, quality: 80) {
					...GatsbyImageSharpFluid
				}
			}
		}

		site {
			siteMetadata {
				routes {
					anchorText
					path
					openInNewTab
				}
			}
		}
	}
`